body {
  background-color: '#262D63 !important';
  box-shadow: '3px 3px 6px #0000000F';
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'AvenirNextCondensed',
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: white !important;
}
