.container {
  height: 100vh;
  width: 100vw;
  background-color: #1a1f71;
  justify-content: center;
  align-items: center;
  background-size: cover;
}
.box {
  height: 600px;
  width: 508px;
  background-image: url('../../../assets/images/intersection_9.png'),
    linear-gradient(#532375 0%, #352174 50%, #141757 75%);
  background-size: cover;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  position: relative;
}
.headerText {
  max-height: 130px;
  margin-top: 50px;
}
.descriptionContainer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.description {
  font-size: 20px;
  font-family: 'AvenirNextCondensed-DemiBoldItalic';
  color: white;
  max-width: 180px;
  text-align: center;
}
.mobileImage {
  flex-grow: 1;
  left: 70px;
}
.desktopMobileImage {
  max-height: 335px;
  max-width: 200px;
  position: absolute;
  bottom: 0;
  left: 70px;
}
.leftContainer {
  position: absolute;
  right: 70px;
  bottom: 40px;
  z-index: 9999;
}
.qrCode {
  max-width: 109px;
  max-height: 109px;
  margin: auto;
}
.appleStore,
.googlePlay {
  width: 137px;
  height: 38px;
  margin-top: 15px;
  background-color: white;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.applicationImage {
  height: 23px;
}
.bgGradientBottom {
  width: 100%;
  height: 200px;
  position: absolute;
  opacity: 60%;
  bottom: 0;
  border-radius: 15px;
}
.headerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
